import {
  convertAPISubscriptionToNormal,
  convertSubscriptionToAPI,
  Member,
  Res,
} from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import transformPagination from 'common/transformPagination'
import { toParam } from 'common/utils/urls/param'
import { companyService } from './useCompany'
import { getStore } from 'common/store'
import { createUbo, deleteUbo, getUbos, updateUbo } from './useUbo'

export const updateMembers = async (
  companyId: string,
  subscriptionId: string,
  membersToRemove: string[],
  members: Member[],
) => {
  const deletes = await Promise.all(
    (membersToRemove || []).map((v) => {
      return deleteUbo(getStore(), {
        company_id: companyId,
        id: v,
        subscription_id: subscriptionId,
      })
    }),
  )
  const updates = await Promise.all(
    (members || []).map((v) => {
      return v.id
        ? updateUbo(getStore(), {
            company_id: companyId,
            data: v,
            subscription_id: subscriptionId,
          })
        : createUbo(getStore(), {
            company_id: companyId,
            data: v,
            subscription_id: subscriptionId,
          })
    }),
  )
  return updates.concat(deletes)
}

export const subscriptionService = service
  .enhanceEndpoints({ addTagTypes: ['Subscription'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createSubscription: builder.mutation<
        Res['subscriptions'],
        Req['createSubscription']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'Subscription' }],
        queryFn: async (query, _1, _2, baseQuery) => {
          // const formData = toFormData(
          //   convertSubscriptionToAPI(query.subscription),
          // )
          const { data, error } = await baseQuery({
            body: convertSubscriptionToAPI({
              ...query.subscription,
              members: [],
            }),
            method: 'POST',
            url: `partners/company/${query.company_id}/subscriptions`,
          })
          await updateMembers(
            query.company_id,
            data.subscription_id,
            query.membersToRemove,
            query.subscription.members,
          )
          getStore().dispatch(companyService.util.invalidateTags(['Company']))
          return { data, error }
        },
      }),
      getSubscription: builder.query<
        Res['subscription'],
        Req['getSubscription']
      >({
        providesTags: (res) => [{ id: res?.id, type: 'Subscription' }],
        query: (query: Req['getSubscription']) => ({
          url: `partners/company/${query.customerId}/subscriptions/${query.id}`,
        }),
        transformResponse: async (res, _, req) => {
          const { data } = await getUbos(
            getStore(),
            {
              company_id: req.customerId,
              subscription_id: req.id,
            },
            {
              forceRefetch: true,
            },
          )
          return convertAPISubscriptionToNormal({
            ...res,
            members: data.results,
          })
        },
      }),
      getSubscriptions: builder.query<
        Res['subscriptions'],
        Req['getSubscriptions']
      >({
        providesTags: [{ id: 'LIST', type: 'Subscription' }],
        query: (q) => ({
          url: `partners/subscriptions?${toParam(q)}`,
        }),
        transformResponse: (res, _, req) =>
          transformPagination(req, res) as any,
      }),
      updateSubscription: builder.mutation<
        Res['subscription'],
        Req['updateSubscription']
      >({
        invalidatesTags: (res) => [
          { id: 'LIST', type: 'Subscription' },
          { id: res?.id, type: 'Subscription' },
        ],
        queryFn: async (query, _1, _2, baseQuery) => {
          getStore().dispatch(companyService.util.invalidateTags(['Company']))

          const newData = {
            ...query.subscription,
            members: undefined,
          }
          const body = convertSubscriptionToAPI(newData)
          const { data, error } = await baseQuery({
            body,
            method: 'PUT',
            url: `partners/company/${query.company_id}/subscriptions/${query.subscription.id}`,
          })
          await updateMembers(
            `${query.company_id}`,
            `${query.subscription.id}`,
            query.membersToRemove,
            query.subscription.members,
          )
          return { data, error }
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getSubscriptions(
  store: any,
  data: Req['getSubscriptions'],
  options?: Parameters<
    typeof subscriptionService.endpoints.getSubscriptions.initiate
  >[1],
) {
  return store.dispatch(
    subscriptionService.endpoints.getSubscriptions.initiate(data, options),
  )
}
export async function createSubscription(
  store: any,
  data: Req['createSubscription'],
  options?: Parameters<
    typeof subscriptionService.endpoints.createSubscription.initiate
  >[1],
) {
  return store.dispatch(
    subscriptionService.endpoints.createSubscription.initiate(data, options),
  )
}
export async function updateSubscription(
  store: any,
  data: Req['updateSubscription'],
  options?: Parameters<
    typeof subscriptionService.endpoints.updateSubscription.initiate
  >[1],
) {
  return store.dispatch(
    subscriptionService.endpoints.updateSubscription.initiate(data, options),
  )
}
export async function getSubscription(
  store: any,
  data: Req['getSubscription'],
  options?: Parameters<
    typeof subscriptionService.endpoints.getSubscription.initiate
  >[1],
) {
  return store.dispatch(
    subscriptionService.endpoints.getSubscription.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useCreateSubscriptionMutation,
  useGetSubscriptionQuery,
  useGetSubscriptionsQuery,
  useUpdateSubscriptionMutation,
  // END OF EXPORTS
} = subscriptionService

/* Usage examples:
const { data, isLoading } = useGetSubscriptionsQuery({ id: 2 }, {}) //get hook
const [createSubscription, { isLoading, data, isSuccess }] = usecreateSubscriptionMutation() //create hook
subscriptionService.endpoints.getSubscriptions.select({id: 2})(store.getState()) //access data from any function
*/
