import { FC, useEffect, useMemo, useState } from 'react'
import {
  CompanySubscriptionSummary,
  CustomerStatus,
  CustomerStatuses,
} from 'common/types/responses'
import classNames from 'classnames'
import Link from 'next/link'
import { FilterInputType } from 'components/tables/FilterInput'
import { useGetSubscriptionsQuery } from 'common/services/useSubscription'
import Loader from 'components/base/Loader'
import { ErrorMessage } from 'components/base/Messages'
import TableFilter from 'components/tables/TableFilter'
import Button from 'components/base/forms/Button'
import NoResultsMessage from 'components/tables/NoResultsMessage'
import { useRouter } from 'next/router'
import Pagination from 'components/Pagination'
import PlanName from './PlanName'
import dateAndTime from 'common/utils/date/dateAndTime'
import SubscriptionStatus from './SubscriptionStatus'
import Stats from './Stats'
import DateRangePicker from 'components/base/forms/date/DateRangePicker'
import dayjs from 'dayjs'
import { fromParam, toParam } from 'common/utils/urls/param'
import ContentContainer from 'components/tables/ContentContainer'
import { usePermission, useUser } from 'common/services/useUser'
import Icon from 'project/Icon'
import EllipsisDropdown from 'components/base/EllipsisDropdown'
import DropdownItem from 'components/base/forms/DropdownItem'
import CancelSubscription from 'components/CancelSubscription'
import { trackCancelSubscription } from 'common/utils/track-subscription'
import KYCStatus from './KYCStatus'

type CustomerTableType = {}

const SubscriptionTable: FC<CustomerTableType> = ({}) => {
  const params = fromParam()
  const [status, setStatus] = useState<CustomerStatus[]>(
    params.status ? params.status.split(',') : [],
  )
  const { data: user } = useUser({})
  const [searchInput, setSearchInput] = useState(params.q || '')
  const [dateFrom, setDateFrom] = useState(params.date_from || undefined)
  const [dateTo, setDateTo] = useState(params.date_to || undefined)
  const [page, setPage] = useState(1)
  const canEdit = usePermission('WRITE')
  const router = useRouter()
  const [activeCustomerId, setActiveCustomerId] = useState<number>()
  const [activeSubscription, setActiveSubscription] =
    useState<CompanySubscriptionSummary>()
  const [activeSubscriptionId, setActiveSubscriptionId] = useState<number>()
  const [showCancel, setShowCancel] = useState(false)

  const filters = useMemo(() => {
    return [
      {
        label: 'Status',
        onChange: setStatus,
        options: CustomerStatuses as any,
        value: status,
      },
    ] as FilterInputType['filters']
  }, [status])

  const { data, error, isFetching, isLoading } = useGetSubscriptionsQuery({
    date_from: dateFrom,
    date_to: dateTo,
    page,
    page_size: 20,
    q: searchInput,
    status,
  })

  useEffect(() => {
    router.replace(
      `${document.location.pathname}?${toParam({
        date_from: dateFrom,
        date_to: dateTo,
        page,
        q: searchInput,
        status: status.join(','),
      })}`,
    )
    //eslint-disable-next-line
  }, [searchInput, dateFrom, dateTo, page, status])

  return isLoading ? (
    <div className='text-center'>
      <Loader />
    </div>
  ) : (
    <>
      <div className='d-none d-xl-block'>
        <Stats />
      </div>

      <div className='d-flex align-items-center justify-content-between gap-4'>
        <div className='flex-1'>
          <TableFilter
            placeholder='Search subscriptions...'
            customFilters={
              <div className='me-4'>
                <DateRangePicker
                  selectRange
                  showDoubleView
                  placeholder='Filter by date'
                  showNeighboringMonth={false}
                  onChange={(v) => {
                    // @ts-ignore
                    if (v?.[0] && v?.[1]) {
                      // @ts-ignore
                      setDateFrom(dayjs(v[0]).format('YYYY-MM-DD'))
                      // @ts-ignore
                      setDateTo(dayjs(v[1]).format('YYYY-MM-DD'))
                    } else {
                      setDateFrom(undefined)
                      setDateTo(undefined)
                    }
                  }}
                  value={
                    dateFrom && dateTo
                      ? [
                          dayjs(dateFrom, 'YYYY-MM-DD').toISOString(),
                          dayjs(dateTo, 'YYYY-MM-DD').toISOString(),
                        ]
                      : null
                  }
                />
              </div>
            }
            filters={filters}
            onChange={setSearchInput}
            value={searchInput}
          />
        </div>
      </div>

      {canEdit && (
        <div className='d-flex justify-content-end mb-5'>
          <Link href={'/customers/create'}>
            <Button>
              <div className='d-flex gap-2 align-items-center'>
                <Icon fill='white' name='add-user' />
                Add Customer
              </div>
            </Button>
          </Link>
        </div>
      )}
      <ErrorMessage className='mb-4'>{error}</ErrorMessage>

      <ContentContainer className='pb-1'>
        <table
          className={classNames({
            disabled: isFetching,
          })}
        >
          <thead>
            <tr>
              <th className='text-nowrap d-flex flex-1'>Company</th>
              <th
                style={{ width: 300 }}
                className='d-md-table-cell d-none text-nowrap'
              >
                Subscription
              </th>
              <th
                style={{ width: 180 }}
                className='d-md-table-cell d-none text-nowrap'
              >
                Date
              </th>
              <th style={{ width: 100 }} className='text-nowrap'>
                Status
              </th>
              <th style={{ width: 100 }} className='text-nowrap'>
                KYC Status
              </th>
              <th style={{ width: 50 }} className='text-nowrap'></th>
            </tr>
          </thead>
          <tbody>
            <NoResultsMessage data={data} />
            {data?.results?.map((subscription) => {
              const goSubscription = () => {
                router.push(
                  `/customers/${encodeURIComponent(
                    `${subscription.company_id}`,
                  )}/subscriptions/${encodeURIComponent(subscription.id)}`,
                )
              }
              const goCompany = () => {
                router.push(
                  `/customers/${encodeURIComponent(
                    `${subscription.company_id}`,
                  )}`,
                )
              }
              return (
                <tr style={{ verticalAlign: 'middle' }} key={subscription.id}>
                  <td
                    onClick={goCompany}
                    className='d-none fw-bold cursor-pointer d-md-table-cell hover-primary'
                  >
                    {subscription.company_name}
                  </td>
                  <td className='d-table-cell d-md-none'>
                    <div
                      onClick={goCompany}
                      className='fw-bold pb-2 hover-primary'
                    >
                      {subscription.company_name}
                    </div>
                    <div
                      onClick={goSubscription}
                      className='fw-semibold hover-primary d-block d-md-none'
                    >
                      <PlanName id={subscription.plan_id} />
                      <div>{subscription.email_address}</div>
                    </div>
                  </td>
                  <td
                    onClick={goSubscription}
                    className='d-none hover-primary cursor-pointer hover-target d-md-table-cell'
                  >
                    <PlanName id={subscription.plan_id} />
                  </td>
                  <td className='d-none d-md-table-cell'>
                    {dateAndTime(subscription.created_at)}
                  </td>
                  <td>
                    <SubscriptionStatus subscription={subscription} />
                  </td>
                  <td>
                    <KYCStatus subscription={subscription} />
                  </td>
                  <td>
                    <EllipsisDropdown>
                      <DropdownItem
                        icon={<Icon name='edit' />}
                        onClick={() => {}}
                      >
                        Edit Subscription
                      </DropdownItem>
                      <DropdownItem
                        icon={<Icon name='delete' fill='danger' />}
                        onClick={() => {
                          setActiveCustomerId(subscription.company_id)
                          setActiveSubscription(subscription)
                          setActiveSubscriptionId(subscription.id)
                          setShowCancel(true)
                        }}
                      >
                        <span className='text-danger'>Cancel Subscription</span>
                      </DropdownItem>
                    </EllipsisDropdown>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </ContentContainer>
      {!!activeCustomerId && (
        <CancelSubscription
          isOpen={showCancel}
          setIsOpen={setShowCancel}
          customerId={activeCustomerId!}
          id={activeSubscriptionId!}
          onCancel={() => {
            trackCancelSubscription(activeSubscription!, user!)
          }}
        />
      )}

      {!!data && <Pagination data={data} onChange={setPage} />}
    </>
  )
}

export default SubscriptionTable
