import { FC } from 'react'
import {
  CompanySubscriptionDetail,
  CompanySubscriptionSummary,
  CustomerStatus,
} from 'common/types/responses'
import Badge from 'components/base/Badge'
import { enumeration } from 'common/utils/string/enumeration'

type SubscriptionStatusType = {
  subscription: CompanySubscriptionDetail | CompanySubscriptionSummary
  className?: string
  subscriptionStatus?: CustomerStatus
}

const SubscriptionStatus: FC<SubscriptionStatusType> = ({
  className,
  subscription,
  subscriptionStatus,
}) => {
  const status = subscriptionStatus || subscription.subscription_state
  if (!status) return null
  return (
    <div className='d-flex'>
      <div>
        <Badge
          className={className}
          theme={
            status === 'ACTIVE'
              ? 'success'
              : status === 'INACTIVE'
              ? 'danger'
              : 'warning'
          }
        >
          {enumeration.get(status)}
        </Badge>
      </div>
    </div>
  )
}

export default SubscriptionStatus
